









































































import UserAccountModule from '@/store/modules/UserAccountModule';
import { Component, Vue } from 'vue-property-decorator';
import accounterrors from '@/pages/account/components/AccountErrors.vue';

@Component({
  components: {
    accounterrors,
  },
})
export default class ForgotPasswordPage extends Vue {
  usernameoremail: string | null = null;
  showValidateMessage = false;

  async sendForgotPasswordEmail() {
    if (!this.validateAll) {
      this.showValidateMessage = true;
    } else {
      this.showValidateMessage = false;
      await UserAccountModule.sendForgotPasswordEmail(
        this.usernameoremail!,
      );
    }
  }

  get sendForgotPasswordEmailSucceeded() {
    return UserAccountModule.sendForgotPasswordEmailSucceeded;
  }

  get sendForgotPasswordEmailProcessing() {
    return UserAccountModule.sendForgotPasswordEmailProcessing;
  }

  get sendForgotPasswordEmailError() {
    return UserAccountModule.sendForgotPasswordEmailError;
  }

  get validateAll(): boolean {
    return this.validateUsernameOrEmail === true;
  }

  get validateUsernameOrEmail() {
    if (this.usernameoremail) {
      return this.usernameoremail.length > 0;
    } else if (this.showValidateMessage) {
      return false;
    } else {
      return null;
    }
  }
}
